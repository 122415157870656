import { useEffect, useState } from 'react'
import {
  Box,
  Center,
  Col,
  Grid,
  Group,
  Pagination,
  ScrollArea,
  Table,
  Text,
  TextInput,
  UnstyledButton,
} from '@mantine/core'
import { keys } from '@mantine/utils'
import { currencyFormatter } from '@ospace/common'
import { parseDate, sortDates } from '@ospace/shared'
import { IconChevronDown, IconChevronUp, IconSearch, IconSelector } from '@tabler/icons-react'

import { useStyles } from './style'
import { ColDef, NewTableProps, ThProps } from './types'

// Pagination Hook
const usePagination = (tableData: any, itemsPerPage: number) => {
  const [currentPage, setCurrentPage] = useState(1)
  const total = Math.ceil(tableData.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const currentPageData = tableData.slice(startIndex, endIndex)

  return {
    currentPage,
    setCurrentPage,
    total,
    currentPageData,
  }
}

// Sorting Hook which also refreshes data when tableData changes
const useSortData = (tableData: any) => {
  const [sortedData, setSortedData] = useState(tableData)
  useEffect(() => {
    setSortedData(tableData)
  }, [tableData])

  return {
    sortedData,
    setSortedData,
  }
}

// Filter data based on search query
const filterData = (data: any, search: string) => {
  const query = search.toLowerCase().trim()

  return data.filter((item: any) =>
    keys(data[0]).some((key) => item[key]?.toString()?.toLowerCase()?.includes(query))
  )
}

type PayloadType = {
  sortBy: string | null
  reversed: boolean
  search: string
  colDef?: ColDef
}

// Sort data based on payload
const sortData = (data: any[], payload: PayloadType) => {
  if (payload.sortBy === null) {
    return filterData(data, payload.search)
  }

  return filterData(sortPayload(data, payload), payload.search)
}

// sortPayload implements a sort for payloads on the NewTable. It is exported
// so it can be unit tested
export const sortPayload = (data: any, payload: PayloadType) => {
  const sortBy = payload.sortBy!

  if (payload?.colDef?.dataType === 'date')
    return [...data].sort((a: any, b: any) =>
      payload.reversed
        ? sortDates(a[sortBy], b[sortBy], payload?.colDef?.dateFormat)
        : sortDates(b[sortBy], a[sortBy], payload?.colDef?.dateFormat)
    )
  else if (payload?.colDef?.dataType === 'number' || payload?.colDef?.dataType === 'currency')
    return [...data].sort((aRow: SortRow, bRow: SortRow) => {
      const a = Number(aRow[sortBy]) || 0 // the or statements handles NaN
      const b = Number(bRow[sortBy]) || 0
      return payload.reversed ? a - b : b - a
    })
  return [...data].sort((aRow: SortRow, bRow: SortRow) => {
    const a = typeof aRow[sortBy] === 'string' ? (aRow[sortBy] as string) : ''
    const b = typeof bRow[sortBy] === 'string' ? (bRow[sortBy] as string) : ''
    return payload.reversed ? b.localeCompare(a) : a.localeCompare(b)
  })
}
type SortRow = { [key: string]: unknown }

// Table Header Component
const Th = ({ children, reversed, sorted, onSort }: ThProps) => {
  const { classes } = useStyles()
  const Icon = sorted ? (reversed ? IconChevronUp : IconChevronDown) : IconSelector

  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position='apart'>
          <Text fw={600} color='gray' fz='md'>
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size='0.9rem' stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  )
}

export const NewTable = ({
  colDef,
  tableData,
  tableProps,
  modal,
  hideSearch = false,
  hidePagination = false,
  hideHeader = false,
  filter,
  title = '',
}: NewTableProps) => {
  const rowKey = tableProps?.rowKey || 'id'
  const [search, setSearch] = useState('')
  const { sortedData, setSortedData } = useSortData(tableData)
  const [sortBy, setSortBy] = useState<string | null>(null)
  const [reverseSortDirection, setReverseSortDirection] = useState(false)
  const { currentPage, setCurrentPage, currentPageData, total } = usePagination(
    sortedData,
    tableProps?.itemsPerPage || 10
  )

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    setCurrentPage(1)
    setSearch(value)
    setSortedData(sortData(tableData, { sortBy, reversed: reverseSortDirection, search: value }))
  }

  const setSorting = (field: string) => {
    const reversed = field === sortBy ? !reverseSortDirection : false
    setCurrentPage(1)
    setReverseSortDirection(reversed)
    setSortBy(field)
    setSortedData(
      sortData(tableData, {
        sortBy: field,
        reversed,
        search,
        colDef: colDef.find((def) => def.key === field),
      })
    )
  }

  const handleRowClick = (row: any) => {
    modal && modal.setModalData(row)
    modal && modal.open()
  }

  const getCellContent = (col: ColDef, element: any) => {
    if (col.element) return col.element(element)
    if (col.dataType === 'currency') {
      // round to 2 decimal places
      const value = Math.round(element[col.key] * 100) / 100
      return `${col.currency} ${currencyFormatter().format(value)}`
    }
    if (col.dataType === 'date') return parseDate(element[col.key], col.dateFormat)

    return element[col.key]
  }

  return (
    <Box p='md'>
      <Grid grow>
        <Col span={3}>
          <Text c='dimmed' fw={600} fz='14px' tt={'uppercase'}>
            {title}
          </Text>
        </Col>
        <Col span={9}>
          {!hideSearch && (
            <TextInput
              placeholder='Search'
              mb='md'
              icon={<IconSearch size='0.9rem' stroke={1.5} />}
              value={search}
              onChange={handleSearchChange}
              w={'60%'}
            />
          )}
        </Col>
      </Grid>

      {filter && filter([...tableData], setSortedData)}
      <ScrollArea>
        <Table highlightOnHover fontSize='md' {...tableProps}>
          {!hideHeader && (
            <thead>
              <tr key='header'>
                {colDef.map((th) => (
                  <Th
                    sorted={sortBy === th.key}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting(th.key)}
                    key={th.key}
                  >
                    {th.label}
                  </Th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {currentPageData?.map((element: any) => (
              <tr
                key={element[rowKey] || element.oppNumber}
                onClick={() => handleRowClick(element)}
              >
                {colDef.map((col) => {
                  return (
                    <td
                      key={col.key}
                      style={{
                        maxWidth: col.width || '200px',
                        minWidth: col.width || '',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingRight: 40,
                        cursor: modal ? 'pointer' : 'auto',
                      }}
                    >
                      {getCellContent(col, element)}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </ScrollArea>
      {!hidePagination && (
        <Center m='xl'>
          <Pagination value={currentPage} onChange={setCurrentPage} total={total} />
        </Center>
      )}
      {modal && modal.component}
    </Box>
  )
}
