import { useState } from 'react'
import {
  Avatar,
  Badge,
  Center,
  createStyles,
  Grid,
  Loader,
  Paper,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { ColDef, NewTable } from '@ospace/shared'
import dayjs from 'dayjs'

import { DealModal } from './DealModal'

type TableData = {
  dealCampaignId: number
  dealId: number
  dealData: any
  companyData: any
  contactData: any
  createdAt: string
  noteCreatedAt: string
  dealViewedAt: string
  noteBody: string
  newOpportunity: boolean
  newActivity: boolean
}

enum DealLabel {
  TotalOpportunities = 'Total Opportunities',
  MeetingsBooked = 'Meetings Booked',
  HighTouchNurture = 'High Touch Nurture',
  NewOpportunities = 'New Opportunities',
  NewActivities = 'New Acitivites',
  Opportunities = 'Opportunities',
  leadReview = 'a Lead – Review',
  leadRequested = 'a Lead – Requested',
  leadApproved = 'a Lead Approved',
}

const status = {
  'a Lead – Requested': 'HTN',
  'a Lead – Review': 'Meeting Booked',
  'a Lead Approved': 'Meeting Complete',
}

const useStyles = createStyles(() => ({
  companyLogoText: {
    textWrap: 'wrap',
    lineHeight: '1.1',
  },
  roleText: {
    textWrap: 'balance',
  },
}))

const OpportunitiesTable = ({ deals }: { deals: any }) => {
  const [opened, { open, close }] = useDisclosure(false)
  const [modalData, setModalData] = useState(deals)
  const { classes } = useStyles()

  const colDef: ColDef[] = [
    {
      key: 'status',
      label: 'Status',
      element: (row) => (
        <Badge color='gray' variant='filled'>
          {status[row.status as keyof typeof status]}
        </Badge>
      ),
      hideHeader: true,
    },
    {
      key: 'logo',
      label: 'Company',
      element: (row) => {
        return (
          <Stack justify='center' align='center' spacing='0'>
            {row.logoUrl && <Avatar size={50} src={row.logoUrl} alt={row.company} />}
            <Text
              ta='center'
              pt={row.logoUrl ? 2 : 0}
              size={row.logoUrl ? 'xs' : 'md'}
              className={classes.companyLogoText}
            >
              {row.company}
            </Text>
          </Stack>
        )
      },
    },
    {
      key: 'contact',
      label: 'Contact',
      element: (row) => {
        return (
          <Stack spacing={'xs'}>
            <Text>{row.contact}</Text>
            <Text className={classes.roleText}>{row.role}</Text>
          </Stack>
        )
      },
    },
    {
      key: 'createdAt',
      label: 'Date',
      element: (row) => dayjs(row.createdAt).format('DD/MM/YYYY'),
    },
    {
      key: 'note',
      label: 'Note',
      width: '400px',
      element: (row) => {
        const html = row.noteBody

        //extract text from html string

        const text = html.replace(/<[^>]+>/g, '')

        return (
          <Text truncate='end' lineClamp={4} style={{ whiteSpace: 'normal' }}>
            {text}
          </Text>
        )
      },
    },
    {
      key: 'newOpportunity',
      label: 'New Opportunity',
      element: (row) => (
        <>
          {row.newOpportunity ? (
            <Badge color='red' variant='filled'>
              New Opportunity
            </Badge>
          ) : row.newActivity ? (
            <Badge color='green' variant='filled'>
              New Activity
            </Badge>
          ) : null}
        </>
      ),
    },
  ]

  if (!deals.data) {
    return <Loader />
  }
  const data = deals?.data?.map((deal: TableData) => {
    return {
      campaignId: deal.dealCampaignId,
      dealId: deal.dealId,
      status: deal.dealData?.properties.ospace_status,
      logoUrl: deal.companyData?.properties?.logoUrl || '',
      company: deal.companyData?.properties?.name || '-',
      contact:
        deal.dealData?.properties.contact_name ||
        `${deal.contactData?.properties?.firstname} ${deal.contactData?.properties?.lastname}` ||
        '-',
      role: deal.contactData?.properties?.jobtitle || '-',
      newActivity: deal.noteCreatedAt > deal.dealViewedAt ? true : false, //Randonly set true or false
      newOpportunity: deal.dealViewedAt === null,
      noteBody: deal.noteBody || '-',
      createdAt: deal.dealData.createdAt,
      dealViewedAt: deal?.dealViewedAt,
    }
  })

  const Filter = (data: TableData[], setData: any) => {
    const [selected, setSelected] = useState(DealLabel.TotalOpportunities as string)

    const onClickHandler = (label: string) => {
      setSelected(label)

      switch (label) {
        case DealLabel.TotalOpportunities:
          return setData(data)
        case DealLabel.NewOpportunities:
          return setData(data.filter((deal: TableData) => deal.newOpportunity))
        case DealLabel.NewActivities:
          return setData(data.filter((deal: TableData) => deal.newActivity))
        default:
          return setData(
            data.filter(
              (deal: any) =>
                deal.status ===
                (label === DealLabel.HighTouchNurture
                  ? DealLabel.leadRequested
                  : label === DealLabel.MeetingsBooked
                    ? DealLabel.leadReview
                    : DealLabel.leadApproved)
            )
          )
      }
    }
    return (
      <Center m={'md'}>
        <Grid>
          {[
            {
              label: DealLabel.TotalOpportunities,
              value: data?.length,
            },
            {
              label: DealLabel.MeetingsBooked,
              value: data?.filter((deal: any) => deal.status === DealLabel.leadReview).length,
            },
            {
              label: DealLabel.HighTouchNurture,
              value: data?.filter((deal: any) => deal.status === DealLabel.leadRequested).length,
            },
            {
              label: DealLabel.NewOpportunities,
              value: data?.filter((deal: any) => deal.newOpportunity).length,
            },
            {
              label: DealLabel.NewActivities,
              value: data?.filter((deal: any) => deal.newActivity).length,
            },
          ].map(({ label, value }) => (
            <Stack
              style={{
                cursor: 'pointer',
                borderRight: label !== DealLabel.NewActivities ? '1px solid gray' : '',
                backgroundColor: selected === label ? '#e8eef3' : '',
              }}
              spacing={'xs'}
              px={'lg'}
              my={'lg'}
              onClick={() => onClickHandler(label)}
            >
              <Text c='dimmed' fw={500} fz='14px' tt={'uppercase'} mt={10}>
                {label}
              </Text>
              <Center fw={700} fz='24px' c='gray.7' mb={5}>
                {value}
              </Center>
            </Stack>
          ))}
        </Grid>
      </Center>
    )
  }

  return data?.length > 0 ? (
    <Paper withBorder p='xl' radius='md' shadow='xs'>
      <NewTable
        tableData={data}
        colDef={colDef}
        hideHeader={true}
        filter={Filter}
        title='Opportunities'
        tableProps={{
          itemsPerPage: 6,
          verticalSpacing: 'lg',
          horizontalSpacing: 0,
        }}
        modal={{
          component: <DealModal opened={opened} onClose={close} data={modalData} />,
          setModalData,
          open,
        }}
      />
    </Paper>
  ) : (
    <Title>No Opportunities</Title>
  )
}
//fbbmbb.vodafone.com
export default OpportunitiesTable
